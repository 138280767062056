import xlsx from 'xlsx';

/**
 * Write to a spreadsheet file
 * @param {string} name - file name and extension
 * @param {string[]} headers - column names for output file
 * @param {object[]} rowArrays - an array of arrays for the rows and columns of the spreadsheet
 */
export function writeSheet(name, headers, rowArrays) {
  const wb = xlsx.utils.book_new();
  const sheet = xlsx.utils.aoa_to_sheet([headers, ...rowArrays]);
  xlsx.utils.book_append_sheet(wb, sheet);
  xlsx.writeFile(wb, name);
}

export function readFile(e) {
  const files = e.target.files;
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onload = e => {
      const workbook = xlsx.read(new Uint8Array(e.target.result), {
        type: 'array'
      });

      const rows = xlsx.utils.sheet_to_json(
        workbook.Sheets[workbook.SheetNames[0]],
        {
          header: [`position`, `stat`, `whichGames`, `type`, `value`],
          range: 1
        }
      );
      resolve(rows);
    };

    reader.onerror = err => {
      reject(err);
    };

    reader.readAsArrayBuffer(files[0]);
  });
}
